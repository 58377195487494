import { useAtomValue } from "jotai/utils";
import { copyAtom } from "../../state";
import { Newsletter } from "../Newsletter/Newsletter";
import { Totals } from "../Totals/Totals";
import { Accordion } from "../../Structure/Accordion/Accordion";

export interface AccordionProps {
  heading: string;
  copy: string;
}
interface Props {
  accordion: AccordionProps[];
  newsletterIntro: string;
}

export function Sidebar(props: Props): JSX.Element {
  const copy = useAtomValue(copyAtom);
  const showAccordion: boolean =
    copy.length === 0 && props.accordion.length > 0;
  return (
    <>
      {showAccordion
        ? props.accordion.map((row, index) => {
            return (
              <Accordion key={index} heading={row.heading} copy={row.copy} />
            );
          })
        : null}
      <Totals />
      <Newsletter newsletterIntro={props.newsletterIntro} />
    </>
  );
}
