import { useEffect, useRef } from "react";

type UnknownFunc = (...args: unknown[]) => void;

export function useTimeout(callback: UnknownFunc, delay: number) {
  const savedCallback = useRef<UnknownFunc>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current ? savedCallback.current() : null;
    }

    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}
