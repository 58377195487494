import { useAtomValue } from "jotai/utils";
import { copyAtom, genderIndexAtom } from "../../state";
import styles from "./Copy.module.css";

export function Copy() {
  const copy = useAtomValue(copyAtom);
  const genderIndex = useAtomValue(genderIndexAtom);
  if (copy.length < 1) {
    return null;
  }
  const gendered_copy = copy.map((item, index) => {
    const gender = genderIndex[index];
    if (item.charCodeAt(0) === 10) {
      return (
        <span key={index} className="pre">
          {item}
        </span>
      );
    }
    return (
      <span key={index} className={gender}>
        {item}
      </span>
    );
  });

  return <div className={styles.Copy}>{gendered_copy}</div>;
}
