import type { AccordionProps } from "../components/Elements/Sidebar/Sidebar";
import Head from "next/head";
import { Header } from "../components/Sections/Header/Header";
import { Footer } from "../components/Sections/Footer/Footer";
import { Container } from "../components/Structure/Container/Container";
import { MainAndAside } from "../components/Structure/MainAndAside/MainAndAside";
import { Form } from "../components/Elements/Form/Form";
import { Sidebar } from "../components/Elements/Sidebar/Sidebar";

interface Props {
  title: string;
  heading: string;
  header: string;
  intro: string;
  newsletterIntro: string;
  accordion: AccordionProps[];
}

export default function HomePage(props: Props) {
  return (
    <>
      <Head>
        <title>{props.title}</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="preload"
          type="font/woff2"
          as="font"
          href="/assets/fonts/Gilroy/Gilroy-Regular.woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          type="font/woff2"
          as="font"
          href="/assets/fonts/Gilroy/Gilroy-Heavy.woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          type="font/woff2"
          as="font"
          href="/assets/fonts/Gilroy/Gilroy-Light.woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          type="font/woff2"
          as="font"
          href="/assets/fonts/Gilroy/Gilroy-Bold.woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          type="font/woff2"
          as="font"
          href="/assets/fonts/Gilroy/Gilroy-Semibold.woff2"
          crossOrigin=""
        />
      </Head>
      <Header heading={props.heading} header={props.header} />
      <Container>
        <MainAndAside>
          <MainAndAside.Main>
            <div
              className="gapBase"
              dangerouslySetInnerHTML={{ __html: props.intro }}
            />
            <Form />
          </MainAndAside.Main>
          <MainAndAside.Aside>
            <Sidebar
              accordion={props.accordion}
              newsletterIntro={props.newsletterIntro}
            />
          </MainAndAside.Aside>
        </MainAndAside>
      </Container>
      <Footer />
    </>
  );
}

export async function getStaticProps() {
  const props: Props = {
    title: "The 9am Gender Decoder",
    heading: "The 9am",
    header:
      "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    intro:
      "<h2>Are your job ads gender neutral?</h2><h3>Fill in your job advert here and see if your ad is gender neutral.</h3>",
    newsletterIntro:
      "<h3>Wanna find out more?</h3><p>Subscribe to gain extra insights, such as the full list of masculine- and feminine-coded words, and ways in which we can help to improve your job adverts.</p>",
    accordion: [
      {
        heading: "Where do the words come from?",
        copy:
          "<p> Requires a computer running an operating system. The computer must have some memory and ideally some kind of long-term storage. An input device as well as some form of output device is recommended. </p>",
      },
      {
        heading: "What happens to the data in my job advert?",
        copy:
          "<p> Data about your job ad is only stored in the database so that you can have a link to share with other people. The ads aren't posted publicly anywhere else, we don't publish a list of the share links, and we don't share the ad text or links with anyone else. We occasionally do a little data analysis, to see which coded words come up most often. But that's it. </p> <p> If you're not comfortable having your data stored and used this way, get in touch with and we'll remove it from the database. </p>",
      },
    ],
  };
  return { props: props };
}
