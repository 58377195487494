import type { PropsWithChildren } from "react";

interface LinkProps {
  href: string;
  id: string;
  className?: string;
  target?: "_blank" | "_self" | "_top" | "_parent";
}

export function Link(props: PropsWithChildren<LinkProps>) {
  const isExternal = props.href.includes("://");
  const rel = isExternal ? "noopener noreferrer" : undefined;
  const target = isExternal ? "_blank" : props.target;

  return (
    <a
      id={props.id}
      rel={rel}
      target={target}
      href={props.href}
      className={props.className ?? undefined}
    >
      {props.children}
    </a>
  );
}
