import { atomWithReset } from "jotai/utils";
import { f_list } from "../config/lists/feminine";
import { m_list } from "../config/lists/masculine";

export type ItemCount = Record<string, number>;
export interface ItemCounter {
  [index: string]: number;
}

export interface Occurrences {
  feminine: ItemCounter;
  masculine: ItemCounter;
}

export function cloneOfOccurrences(obj: Occurrences): Occurrences {
  return JSON.parse(JSON.stringify(obj));
}

const initIndex: Occurrences = {
  feminine: {},
  masculine: {},
};

f_list.forEach((item) => {
  initIndex.feminine[item] = 0;
});
m_list.forEach((item) => {
  initIndex.masculine[item] = 0;
});
export const initialCountIndex: Occurrences = cloneOfOccurrences(initIndex);

export const indexAtom = atomWithReset<Occurrences>(
  cloneOfOccurrences(initialCountIndex)
);
export const genderIndexAtom = atomWithReset<string[]>([]);
export const copyAtom = atomWithReset<string[]>([]);
export const advertAtom = atomWithReset<number>(0);

export function sort_index(index: Occurrences): Occurrences {
  let feminine = Object.entries(index.feminine);
  let masculine = Object.entries(index.masculine);

  return {
    feminine: Object.fromEntries(feminine.sort(([, a], [, b]) => b - a)),
    masculine: Object.fromEntries(masculine.sort(([, a], [, b]) => b - a)),
  };
}
