import type { PropsWithChildren } from "react";
import { FlexCol } from "../Flex/Flex";
import styles from "./MainAndAside.module.css";

export function MainAndAside(
  props: PropsWithChildren<{ className?: string }>
): JSX.Element {
  return (
    <FlexCol className={`${styles.MainAndAside} ${props.className ?? ""}`}>
      {props.children}
    </FlexCol>
  );
}

function Main(props: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <div className={`${styles.Main} ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}
function Aside(props: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <div className={`${styles.Aside} ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}

MainAndAside.Main = Main;
MainAndAside.Aside = Aside;
