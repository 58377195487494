import { Container } from "../../Structure/Container/Container";
import { Link } from "../../Structure/Link/Link";
import {
  Flex,
  FlexColContentCenter,
  FlexRowContentCenter,
  FlexWrap,
} from "../../Structure/Flex/Flex";
import styles from "./Footer.module.css";

const id = "footer";

export function Footer() {
  return (
    <>
      <footer className={styles.Footer}>
        <Container>
          <FlexWrap className={styles.withHeight}>
            <Logo />
            <Social />
            <Awards />
            <Links />
          </FlexWrap>
        </Container>
      </footer>
      <footer className={styles.FooterBase}>
        <Container>
          <Flex className={styles.FooterBaseAlign}>
            <Copyright />
            <Terms />
          </Flex>
        </Container>
      </footer>
    </>
  );
}

function Copyright() {
  return (
    <Flex className={styles.Copyright}>
      © 2021 All content within this site is copyright to We Love 9am Limited.
      Registration number 10270064
    </Flex>
  );
}
function Terms() {
  return (
    <FlexRowContentCenter className={styles.Terms}>
      <Link
        id={`${id}-privacyPolicy`}
        href="https://welove9am.com/privacy-policy"
      >
        Privacy Policy
      </Link>
      <Link
        id={`${id}-cookiesPolicy`}
        href="https://welove9am.com/cookies-policy"
      >
        Cookies Policy
      </Link>
      <Link id={`${id}-gdpr`} href="https://welove9am.com/gdpr">
        General Data Protection Regulation
      </Link>
    </FlexRowContentCenter>
  );
}

function Logo() {
  return (
    <FlexRowContentCenter className={styles.Logo}>
      <Link id={`${id}-logo`} href="https://welove9am.com/">
        <img
          alt="We Love 9am logo"
          src="https://welove9am.com/assets/images/logo-inverse.svg"
          width="193"
          height="41"
        />
      </Link>
    </FlexRowContentCenter>
  );
}

function Social() {
  return (
    <FlexRowContentCenter className={styles.Social}>
      <Link id={`${id}-instagram`} href="https://www.instagram.com/welove9am">
        <img alt="Instagram Logo" src="/assets/instagram.svg" />
      </Link>
      <Link id={`${id}-twitter`} href="https://twitter.com/welove9am">
        <img alt="Twitter Logo" src="/assets/twitter.svg" />
      </Link>
      <Link id={`${id}-facebook`} href="https://www.facebook.com/welove9am">
        <img alt="FaceBook Logo" src="/assets/facebook.svg" />
      </Link>
      <Link
        id={`${id}-linkedin`}
        href="https://www.linkedin.com/company/welove9am/"
      >
        <img alt="LinkedIn Logo" src="/assets/linkedin.svg" />
      </Link>
    </FlexRowContentCenter>
  );
}

function Awards() {
  return (
    <FlexColContentCenter className={styles.Awards}>
      <img
        alt="RAR+ logo"
        src="https://welove9am.com/assets/images/logo-rarplus.svg"
        className={styles.rar}
        width="166"
      />
      <img
        alt="EBMA logo"
        src="https://welove9am.com/assets/images/ebma-logo-lr.png"
        className={styles.ebma}
        width="168"
        srcSet="https://welove9am.com/assets/images/ebma-logo-lr.png, https://welove9am.com/assets/images/ebma-logo@2x.png 2x"
      />
      <img
        alt="In-house Recruitment Awards logo"
        src="https://welove9am.com/assets/images/logo-inhouse.svg"
        className={styles.inhouse}
        width="166"
      />
    </FlexColContentCenter>
  );
}

function Links() {
  return (
    <FlexRowContentCenter className={styles.Links}>
      <Link id={`${id}-home`} href="https://welove9am.com/">
        Home
      </Link>
      <Link id={`${id}-whatWeDo`} href="https://welove9am.com/what-we-do">
        What we do
      </Link>
      <Link id={`${id}-ourWork`} href="https://welove9am.com/our-work">
        Our Work
      </Link>
      <Link id={`${id}-social`} href="https://welove9am.com/social-wall">
        Social Wall
      </Link>
      <Link id={`${id}-careers`} href="https://welove9am.com/careers">
        Careers
      </Link>
      <Link id={`${id}-contact`} href="https://welove9am.com/contact">
        Contact
      </Link>
    </FlexRowContentCenter>
  );
}
