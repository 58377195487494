const list: string[] = [
  "affectionate",
  "agree",
  "cheer",
  "child",
  "co-operat",
  "collab",
  "commit",
  "communal",
  "compassion",
  "connect",
  "considerate",
  "cooperat",
  "depend",
  "emotiona",
  "empath",
  "enthusias",
  "feel",
  "flatterable",
  "gentle",
  "honest",
  "inclusive",
  "inter-dependen",
  "inter-persona",
  "inter-personal",
  "interdependen",
  "interpersona",
  "interpersonal",
  "kind",
  "kinship",
  "loyal",
  "modesty",
  "nag",
  "nurtur",
  "pleasant",
  "polite",
  "quiet",
  "respon",
  "sensitiv",
  "share",
  "sharin",
  "submissive",
  "support",
  "sympath",
  "tender",
  "together",
  "trust",
  "understand",
  "warm",
  "whin",
  "yield",
];

/** Ensure unique via Set */
export const f_list: string[] = [...new Set(list)];
