import type { CSSProperties } from "react";
import styles from "./Donut.module.css";

function offset(percent: number): CSSProperties {
  return { "--offset": 100 - percent } as CSSProperties;
}

export function Donut(props: {
  masculinePercent: number;
  femininePercent: number;
}): JSX.Element {
  const width = 4;

  return (
    <div style={{ position: "relative" }}>
      <svg
        className={styles.Donut}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.FeminineLine}
          r="15.9"
          cy="20"
          cx="20"
          strokeWidth={width}
          fill="none"
        />
        <circle
          className={styles.MasculineLine}
          r="15.9"
          cy="20"
          cx="20"
          strokeWidth={width}
          fill="none"
          style={offset(props.masculinePercent)}
        />
      </svg>
      <div className={styles.Results}>
        <div>Your job advert is</div>
        <div className={styles.FeminineStrong}>
          {props.femininePercent}% feminine
        </div>
        <div className={styles.MasculineStrong}>
          {props.masculinePercent}% masculine
        </div>
      </div>
    </div>
  );
}
