import { useState } from "react";
import { useTimeout } from "../../../utils/hooks/useTimeout";
import styles from "./Codeword.module.css";

const transitionDelay = 50;

export function Codeword(props: {
  word: string;
  before: string;
  after: string;
}): JSX.Element {
  const wordLength: number = props.word.length;
  const wordArray: string[] = props.word.split("");
  const randomIndex = shuffleArray(
    Array.from({ length: wordLength }, (_, i) => i)
  );

  return (
    <>
      {props.before}
      <span style={{ whiteSpace: "nowrap" }}>
        {wordArray.map((letter, index) => {
          return (
            <Letter
              letter={letter}
              delay={transitionDelay * randomIndex[index]}
              key={index}
            />
          );
        })}
      </span>
      {props.after}
    </>
  );
}

function Letter(props: { letter: string; delay: number }): JSX.Element {
  const [codeLetter, setCodeLetter] = useState(" ");

  useTimeout(() => {
    setTimeout(() => {
      changeLetter(1);
    }, transitionDelay);
    setTimeout(() => {
      changeLetter(-2);
    }, transitionDelay * 2);
    setTimeout(() => {
      changeLetter(2);
    }, transitionDelay * 3);
    setTimeout(() => {
      changeLetter(0);
    }, transitionDelay * 4);
  }, props.delay);

  function changeLetter(offset: number) {
    setCodeLetter(String.fromCharCode(props.letter.charCodeAt(0) + offset));
  }

  const fadeClass = codeLetter === " " ? styles.singleWidth : styles.fadeIn;

  return <span className={fadeClass}>{codeLetter}</span>;
}

function shuffleArray(array: number[]) {
  const indexedArray = [...array];
  for (let index = indexedArray.length - 1; index > 0; index--) {
    const randomIndexToSwap = Math.floor(Math.random() * (index + 1));
    [indexedArray[index], indexedArray[randomIndexToSwap]] = [
      indexedArray[randomIndexToSwap],
      indexedArray[index],
    ];
  }

  return indexedArray;
}
