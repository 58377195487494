import type { CSSProperties, PropsWithChildren } from "react";
import { useRef, useState } from "react";
import { Flex } from "../Flex/Flex";
import styles from "./Accordion.module.css";

export function Accordion(
  props: PropsWithChildren<{ heading: string; copy: string }>
): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const bodyRef = useRef<HTMLDivElement>(null);
  const height = bodyRef.current?.clientHeight ?? 0;
  const heightVar = { "--height": `${height}px` } as CSSProperties;

  return (
    <aside className={styles.Accordion}>
      <Flex
        className={styles.Summary}
        onClick={(event) => {
          event.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {props.heading}
        <img
          alt="open-state"
          src="/assets/chevron.svg"
          data-active={isOpen ? true : null}
        />
      </Flex>
      <div
        className={styles.Body}
        data-active={isOpen ? true : null}
        style={heightVar}
      >
        <div ref={bodyRef} dangerouslySetInnerHTML={{ __html: props.copy }} />
      </div>
    </aside>
  );
}
