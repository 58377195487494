import { useAtomValue } from "jotai/utils";
import { useRef } from "react";
import { advertAtom, copyAtom } from "../../state";
import { FlexRowContentRight, Flex } from "../../Structure/Flex/Flex";
import styles from "./Newsletter.module.css";

interface Props {
  newsletterIntro: string;
}

export async function getToken() {
  const response = await fetch("/action/csrf-request/get-token");
  const responseJson = await response.json();
  return responseJson;
}

function subscribe(email: string, advertId: number) {
  getToken().then((data) => {
    if (data.token) {
      const params = new URLSearchParams({
        [data.param]: data.token,
        action: "guest-entries/save",
        sectionUid: "8a492630-28bb-4ef9-aabe-c8c1b09bb9bb",
        "fields[candidateEmail]": email,
        "fields[candidateFullName]": "Guest",
      });
      if (advertId > 0) {
        params.append("fields[relatedVacancy][]", String(advertId));
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: params,
      };
      fetch("/", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.id) {
            location.assign("/full-results-request");
          }
        });
    }
  });
}

export function Newsletter(props: Props): JSX.Element {
  const copy = useAtomValue(copyAtom);
  const advertId = useAtomValue(advertAtom);
  const emailInput = useRef<HTMLInputElement>(null);

  return (
    <form
      className={styles.Newsletter}
      hidden={copy.length ? false : true}
      action="/"
      method="post"
      acceptCharset="UTF-8"
      onSubmit={(event) => {
        event.preventDefault();
        const email = emailInput.current?.value ?? "";
        subscribe(email, advertId);
      }}
    >
      <div
        className="padBase"
        dangerouslySetInnerHTML={{ __html: props.newsletterIntro }}
      />
      <div className="padBase">
        <input
          type="email"
          ref={emailInput}
          required={true}
          placeholder="Work email*"
        />
      </div>
      <Flex className={`padBase ${styles.Checkbox}`}>
        <input required={true} type="checkbox" id="gdpr" />
        <label htmlFor="gdpr">
          I acknowledge and give my permission for my data to be held by
          WeLove9am in accordance with the General Data Protection Regulation.
        </label>
      </Flex>
      <FlexRowContentRight>
        <button type="submit">Subscribe</button>
      </FlexRowContentRight>
    </form>
  );
}
