import { useAtomValue } from "jotai/utils";
import { indexAtom, copyAtom } from "../../state";
import styles from "./List.module.css";

export function List() {
  const index = useAtomValue(indexAtom);
  const feminineRows = Object.entries(index.feminine);
  const masculineRows = Object.entries(index.masculine);
  const copy = useAtomValue(copyAtom);
  if (!copy.length) {
    return null;
  }

  return (
    <div className={styles.List}>
      <div>
        <h4 className={styles.FeminineText}>Top Feminine-coded words: </h4>
        {feminineRows.map((item: [string, number], index: number) =>
          index < 3 ? <OccurrenceCount item={item} index={index} /> : null
        )}
      </div>
      <div>
        <h4 className={styles.MasculineText}>Top Masculine-coded words:</h4>
        {masculineRows.map((item: [string, number], index: number) =>
          index < 3 ? <OccurrenceCount item={item} index={index} /> : null
        )}
      </div>
    </div>
  );
}

function OccurrenceCount(props: {
  item: [string, number];
  index: number;
}): JSX.Element {
  const count = props.item[1];
  const hasOccurred: boolean = count < 1;

  return (
    <li hidden={hasOccurred} key={props.index}>
      {count} use{count > 1 ? "s" : ""} of <strong>{props.item[0]}-</strong>
    </li>
  );
}
