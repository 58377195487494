import { useAtomValue } from "jotai/utils";
import { indexAtom, copyAtom } from "../../state";
import { Donut } from "../Donut/Donut";
import { List } from "../List/List";

export function Totals() {
  const index = useAtomValue(indexAtom);
  const copy = useAtomValue(copyAtom);
  if (!copy.length) {
    return null;
  }

  const feminine_rows = Object.entries(index.feminine);
  const count = { feminine: 0, masculine: 0 };
  feminine_rows.forEach((item) => {
    count.feminine += item[1];
  });

  const masculine_rows = Object.entries(index.masculine);
  masculine_rows.forEach((item) => {
    count.masculine += item[1];
  });

  const total = count.feminine + count.masculine;
  const feminine_percent = Math.round((100 / total) * count.feminine);
  const masculine_percent = Math.round((100 / total) * count.masculine);

  if (total < 1) {
    return null;
  }

  return (
    <section>
      <Donut
        femininePercent={feminine_percent}
        masculinePercent={masculine_percent}
      />
      <List />
    </section>
  );
}
