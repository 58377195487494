import { Codeword } from "../../Elements/Codeword/Codeword";
import { Container } from "../../Structure/Container/Container";
import { Link } from "../../Structure/Link/Link";
import { TextAndImage } from "../../Structure/TextAndImage/TextAndImage";
import styles from "./Header.module.css";

interface Props {
  heading: string;
  header: string;
}

export function Header(props: Props): JSX.Element {
  return (
    <header className={styles.Header}>
      <Container>
        <Link id="header-logo" href="https://welove9am.com">
          <img
            alt="WeLove9am Logo"
            src="/assets/logo.svg"
            width={194}
            height={41}
          />
        </Link>
        <TextAndImage>
          <TextAndImage.Text>
            <h1>
              <Codeword
                before={`${props.heading} `}
                word="Gender Decoder"
                after=""
              />
            </h1>
            <div dangerouslySetInnerHTML={{ __html: props.header }} />
          </TextAndImage.Text>
          <TextAndImage.Image className={styles.Image}>
            <video
              poster=""
              autoPlay={true}
              muted={true}
              loop={true}
              disablePictureInPicture={true}
              disableRemotePlayback={true}
            >
              <source src="/assets/symbols.mp4" />
            </video>
          </TextAndImage.Image>
        </TextAndImage>
      </Container>
    </header>
  );
}
