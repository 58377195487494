const list: string[] = [
  "active",
  "adventurous",
  "aggress",
  "ambitio",
  "analy",
  "assert",
  "athlet",
  "autonom",
  "battle",
  "boast",
  "challeng",
  "champion",
  "compet",
  "confident",
  "courag",
  "decid",
  "decision",
  "decisive",
  "defend",
  "determin",
  "domina",
  "dominant",
  "driven",
  "fearless",
  "fight",
  "force",
  "greedy",
  "head-strong",
  "headstrong",
  "hierarch",
  "hostil",
  "impulsive",
  "independen",
  "individual",
  "intellect",
  "lead",
  "logic",
  "objective",
  "opinion",
  "outspoken",
  "persist",
  "principle",
  "reckless",
  "self-confiden",
  "self-relian",
  "self-sufficien",
  "selfconfiden",
  "selfrelian",
  "selfsufficien",
  "stubborn",
  "superior",
  "unreasonab",
];

/** Ensure unique via Set */
export const m_list: string[] = [...new Set(list)];
