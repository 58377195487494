import { useRef } from "react";
import { useAtom } from "jotai";
import { useUpdateAtom, useResetAtom } from "jotai/utils";
import {
  advertAtom,
  cloneOfOccurrences,
  copyAtom,
  genderIndexAtom,
  indexAtom,
  initialCountIndex,
  sort_index,
} from "../../state";
import { m_list } from "../../../config/lists/masculine";
import { f_list } from "../../../config/lists/feminine";
import { Copy } from "../Copy/Copy";
import { FlexCol, FlexRowContentRight } from "../../Structure/Flex/Flex";
import { getToken } from "../Newsletter/Newsletter";
import styles from "./Form.module.css";

function subscribe(copy: string, setAdvertId: Function) {
  getToken().then((data) => {
    if (data.token) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: new URLSearchParams({
          [data.param]: data.token,
          action: "guest-entries/save",
          sectionUid: "137cd8db-2af7-499b-936f-612fa2c063ed",
          "fields[copy]": copy,
        }),
      };
      fetch("/", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.id) {
            setAdvertId(data.id);
          }
        });
    }
  });
}

export function Form() {
  const inputEl = useRef<HTMLTextAreaElement>(null);

  const [copy, setCopy] = useAtom(copyAtom);
  const setGenderIndex = useUpdateAtom(genderIndexAtom);
  const setIndexes = useUpdateAtom(indexAtom);
  const setAdvertId = useUpdateAtom(advertAtom);

  const resetGenderIndex = useResetAtom(genderIndexAtom);
  const resetIndex = useResetAtom(indexAtom);
  const resetCopy = useResetAtom(copyAtom);
  const resetAdvertId = useResetAtom(advertAtom);

  function reset(): void {
    resetIndex();
    resetGenderIndex();
    resetCopy();
    resetAdvertId();
  }

  function parse_text(): void {
    reset();
    let masculine: string[] = [];
    let feminine: string[] = [];
    let indicies = cloneOfOccurrences(initialCountIndex);
    let genderIndex: string[] = [];
    const current_copy = inputEl.current?.value ?? "";
    const simple_copy_array = current_copy.split(/(\s+)/);
    setCopy(simple_copy_array);

    simple_copy_array.forEach((value, index) => {
      genderIndex.push("");
      const copy_word = value.replace(/\W/g, "");

      if (
        f_list.some((list_string) => {
          const has_mention =
            copy_word.search(new RegExp("^" + list_string, "gi")) >= 0;
          if (has_mention) {
            let count = indicies.feminine[list_string];
            indicies.feminine[list_string] = count + 1;
            genderIndex[index] = "feminine";
          }
          return has_mention;
        })
      ) {
        feminine = [...feminine, value];
      }

      if (
        m_list.some((list_string) => {
          const has_mention =
            copy_word.search(new RegExp("^" + list_string, "gi")) >= 0;
          if (has_mention) {
            let count = indicies.masculine[list_string];
            indicies.masculine[list_string] = count + 1;
            genderIndex[index] = "masculine";
          }
          return has_mention;
        })
      ) {
        masculine = [...masculine, value];
      }
    });

    setIndexes(sort_index(indicies));
    setGenderIndex(genderIndex);
  }

  return (
    <>
      {copy.length === 0 ? (
        <form
          className={styles.Form}
          action="/"
          method="post"
          acceptCharset="UTF-8"
          onSubmit={(event) => {
            event.preventDefault();
            const copy = inputEl.current?.value ?? "";
            subscribe(copy, setAdvertId);
            parse_text();
          }}
        >
          <FlexCol>
            <textarea
              ref={inputEl}
              placeholder="Paste your job advert here..."
              // readOnly={copy.length}
            />
            <FlexRowContentRight className={styles.Group}>
              <button type="submit">Check advert</button>
            </FlexRowContentRight>
          </FlexCol>
        </form>
      ) : (
        <FlexCol>
          <Copy />
          <FlexRowContentRight className={styles.Group}>
            <button type="button" onClick={() => reset()}>
              Check another advert
            </button>
          </FlexRowContentRight>
        </FlexCol>
      )}
    </>
  );
}
