import type { PropsWithChildren } from "react";
import { FlexCol } from "../Flex/Flex";
import { MainAndAside } from "../MainAndAside/MainAndAside";
import styles from "./TextAndImage.module.css";

export function TextAndImage(
  props: PropsWithChildren<{ className?: string }>
): JSX.Element {
  return (
    <FlexCol className={`${styles.TextAndImage} ${props.className ?? ""}`}>
      {props.children}
    </FlexCol>
  );
}

function Text(props: PropsWithChildren<{ className?: string }>): JSX.Element {
  return MainAndAside.Main({ className: styles.Text, ...props });
}
function Image(props: PropsWithChildren<{ className?: string }>): JSX.Element {
  return MainAndAside.Aside({ className: styles.Image, ...props });
}

TextAndImage.Text = Text;
TextAndImage.Image = Image;
