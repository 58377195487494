import type { MouseEventHandler, PropsWithChildren } from "react";
import utilStyle from "../../styles/utilStyle.module.css";

const row = `${utilStyle.flexNoWrap} ${utilStyle.flexRow}`;
const col = `${utilStyle.flexNoWrap} ${utilStyle.flexCol}`;

interface FlexProp extends PropsWithChildren<{}> {
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export function Flex(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${utilStyle.flexNoWrap} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function FlexWrap(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${utilStyle.flexWrap} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function FlexCol(props: FlexProp): JSX.Element {
  return (
    <div className={`${col} ${props.className ?? ""}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export function FlexRowContentRight(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${row} ${utilStyle.contentRight} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function FlexColContentRight(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${col} ${utilStyle.contentRight} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function FlexRowContentCenter(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${row} ${utilStyle.contentCenter} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function FlexColContentCenter(props: FlexProp): JSX.Element {
  return (
    <div
      className={`${col} ${utilStyle.contentCenter} ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
